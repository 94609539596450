.sidebar {
    display: flex;
    flex: 0.35;
    flex-direction: column;
}

.sidebar_search {
    display: flex;
    align-items: center;
    background-color: #82B4D9;
    height: 39px;
    padding: 10px;
}

.sidebar_searchContainer {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;
}

.sidebar_searchContainer > input {
    border: none;
    margin-left: 10px;
}

.sidebar_searchContainer > .MuiSvgIcon-root {
    color: #3F47D9;
    padding: 10px;
}

.sidebar_header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgreen;
}

.sidebar_headerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}

/* Don't think this does anything */
.sidebar_headerRight > .MuiSvgIcon-root {
    margin-right: 2vw;
    font-size: 24px !important;
}

.sidebar_chats {
    flex: 1;
    background-color: #86C6DB;
    overflow: auto;
}