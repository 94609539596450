.sidebarChat {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid lightskyblue;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: black;
}
.sidebarChat:hover {
    background-color: mediumaquamarine;
}

.sidebarChat_info > h2{
    font-size: 16px;
    margin-bottom: 8px;
}

.sidebarChat_info{
    margin-left: 15px;
}