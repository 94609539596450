.app{
  background-color: grey;
  display: grid;
  place-items: center;
  height:100vh;
}

.app_body{
  display: flex;
  margin-top: -50px;
  background-color: #3CB8CF;
  height:90vh;
  width:90vw;
  box-shadow: -1px 4px 20px -6px rgba(0,0,0,0.7);
}