.chat {
    /* background-color: yellow; */
    flex: 0.65;
    display: flex;
    flex-direction: column;
}

.chat_header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgreen;
}

.chat_headerInfo {
    flex: 1;
    padding-left: 20px;
}

.chat_headerInfo > h3 {
    margin-bottom: 3px;
    font-weight: 500;
}

.chat_headerInfo > p {
    color: gray;
}

.chat_headerRight {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}

.chat_body {
    flex: 1;
    /* background-image: url("https://3.bp.blogspot.com/-yCWzhevjj7k/VvG3M43KLUI/AAAAAAACFTQ/b1SUDzztqJ8tQNVMi-uZPw0GtPljPuq5g/s1600/chickie%2Bmochi.jpg"); */
    /* background-image: url("https://i.pinimg.com/originals/f7/9b/5e/f79b5e3af8c741bbc1dafdc01a01e9c2.jpg"); */
    background-size: 200px;
    background-repeat: repeat;
    background-position: center;
    padding: 30px;
    overflow: auto;
    padding-bottom: 50px;
    
}

.chat_message {
    position: relative;
    font-size: 16px;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 30px;
}

.chat_name {
    position: absolute;
    top: -15px;
    font-weight: 800;
    font-size: small;
    /* background-color: white; */
}

.chat_timestamp {
    margin-left: 10px;
    font-size: xx-small;
}

.chat_receiver {
    margin-left: auto;
    background-color: steelblue;
}

.chat_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    border-top: 1px solid lightgray;
}

.chat_footer > form{
    flex: 1;
    display: flex;
    /* background-color: blue; */
}

.chat_footer > form > .input {
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;

}

.chat_footer > .MuiSvgIcon-root {
    padding: 10px;
    color:gray;
}


.chat_message_div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: fit-content;

}

.profilePic {
    margin-right: 5px;
}